$policy-prompt-bg: #f1d6d7;
$policy-prompt-blue: #325ab2;
$policy-prompt-green: #95ffe4;
$policy-prompt-font: 'area-normal', sans-serif;
$policy-prompt-heading-font: 'articulat-cf', sans-serif;

@import '../../../css/global/mixins';
@import './multimedia_page.scss';
@import './multimedia_series_page.scss';
@import './footer.scss';
